import React, { useState } from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// プロジェクトページコンポーネント
import { getCurrentProject, MainImage, ProjectTab, ProjectSideMenu } from "../../components/parts/project"

const ProjectArticlePage = ({ data }) => {  
  const pageType = 'progress'
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const {
    slug,
    title,
    progress,
  } = frontmatter
  const { description } = getCurrentProject(slug)

  const [current, changeCurrent] = useState(0)
  const handleChange = (event) => {
    changeCurrent(event.target.value)
  }
  const filter = (progress.length > 1) ? 
    <div className={styles.selectFilter}>
      <div className={styles.selectFilterInner}>
        <select name="type" value={current} id="makerShareType" onChange={handleChange}>
            { 
              progress.map((item, no) => {
                return <option key={`option-${no}`} value={no}>{item.reportDate}時点</option>
              })
            }
        </select>
      </div>
    </div> : <p className={styles.progressDate}>{progress[0].reportDate}時点</p>

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        ogImage={`https://${process.env.HOST_NAME}/images${ slug.replace(pageType+'/', '') }og.png`}
        path={slug}
      />

      <BreadCrumb
        parentLink="/project/"
        parentTitle="プロジェクト情報"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
      </div>

      <main className={styles.articleContents}>
        <article className={styles.articleContentsLeft}>
          <MainImage slug={slug} current={pageType} alt={title} />
          <ProjectTab current={pageType} slug={slug} />
          
          <div className={`${styles.tabPane} ${styles.activePane}`}>
            <div className={styles.articleInner}>
              <h2><span dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} /></h2>
              <div className="text-right">
                { filter }
              </div>
          { progress.map((item, no) => {
            return <div className={`${(current == no) ? styles.show : styles.hide}`}>
                <h3>プロジェクトサマリー</h3>
                <ul>
                { item.summary.lead.map((leadItem, leadNo) => {
                    return <li key={`progress-lead-${no}-${leadNo}`} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(leadItem) }} />
                  })
                }
                </ul>
                { item.summary.items.map((summaryItem, summaryNo) => {
                    return <>
                      <h4>{summaryItem.heading}</h4>
                      <div key={`progress-summary-${no}-${summaryNo}`} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(summaryItem.content) }} />
                    </>
                  })
                }
                <h3>スケジュール</h3>
                <p><img src={`/images${slug.replace('progress/', '')}schedule-${no}.png`} alt="" /></p>
                <h4>1. NEDO報告資料</h4>
                <p className="pb-1">経済産業省産業構造審議会グリーンイノベーションプロジェクト部会ワーキンググループにおけるNEDO報告はこちら。</p>
                {
                  item.report.map((reportItem, reportNo) => {
                    return <dl className={`reportDefinition ${ (reportNo === item.report.length - 1 ) ? 'mb-4' : 'mb-01' }`} key={`reportNo-${reportNo}`}>
                      <dt>{reportItem.date}
                        {
                          reportNo == 0 ? 
                            <span className={styles.progressRecently}>最新</span> : <></>
                        }
                      </dt>
                      <dd><a className={`${styles.linkText} ${styles.pdf}`} href={reportItem.url} target="_blank" rel="noreferrer noopener">{reportItem.title}</a></dd>
                    </dl>
                  })
                }
                <h4>2. 各事業者報告資料（事業戦略ビジョン）</h4>
                <p>各事業者の進捗状況はこちら。<br />
                  <a href="../scheme/" data-label="progress2system">実施体制・事業戦略ビジョン</a>
                </p>
              </div>
            })
          }
            </div>
          </div>
        </article>
        
        <ProjectSideMenu slug={slug} type={`/${pageType}/`} current={pageType} />
      </main>
    </Layout>
  )
}
export default ProjectArticlePage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        slug
        title
        progress {
          summary {
            lead
            items {
              heading
              content
            }
          }
          report {
            date
            title
            url
          }
          reportDate
        }
      }
    }
  }
`